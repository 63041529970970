import dayjs from 'dayjs'
import { IIndentFilter } from '~/components/composed/indent/type'
import { ITripFilter } from '~/components/composed/trips/type'
import constants from './constants'

type truckGroupType = {
  primary_group_id: number
  truck_type_ids: number[]
}
type routeType = {
  sAliasId: any
  sourceId: number
  sourceBranchId: number
  sourceName: string
  dAliasId: any
  destinationId: number
  destinationBranchId: number
  destinationName: string
}

const now = dayjs()
const yyyymmdd = 'YYYY-MM-DD'

const util = {
  /**
   * Dont modify below date format this format is needed for date range filter variable
   * today | WEEK | MONTH | THREE_MONTH
   */
  today: dayjs().format(yyyymmdd),
  WEEK: now.subtract(7, 'day').format(yyyymmdd),
  MONTH: now.subtract(30, 'day').format(yyyymmdd),
  THREE_MONTH: now.subtract(90, 'day').format(yyyymmdd),
  TODAY_WITH_TIME: now.format(yyyymmdd + ' HH:mm:ss'),
  NEXT_MONTH_WITH_TIME: now.add(30, 'day').format(yyyymmdd + ' HH:mm:ss'),
  DATE: new Date(),
  // ============================================
  isMobileRegex: new RegExp(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i,
  ),
  getSourceIdparam: (search: string) => {
    const splitSearch = search ? search.split('&') : []
    const param = search.includes('source_id') ? splitSearch[0] : ''
    return param
  },
  getNameAndMobile: (name: string | null, mobile: string): string => {
    const modifiedName = name ? `${name} - ${mobile}` : mobile
    return modifiedName
  },
  composeRatesParam: (route: routeType, truckGroup: truckGroupType) => {
    return `?s_id=${route.sourceId}&b_id=${route.sourceBranchId}&d_id=${route.destinationId}&db_id=${route.destinationBranchId}&tg_id=${truckGroup.primary_group_id}`
  },
  validate_pan_format: (pan_number: string) => {
    const pan = pan_number ? pan_number.replace(/\s/g, '') : ''
    const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
    return pan.length === 10 ? regpan.test(pan) : false
  },
  isValidPan: (pan: string) => {
    const panNumber = pan ? pan.replace(/\s/g, '') : ''
    const regexPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
    return regexPan.test(panNumber)
  },
  validate_mobile: (mobile: string) => {
    const length = mobile ? mobile.replace(/\s/g, '').length : ''
    if (length !== 10) {
      throw new Error(`Invalid Mobile Number ${mobile}`)
    }
  },
  isValidMobile: (mobile: string) => {
    const length = mobile ? mobile.replace(/\s/g, '').length : ''
    return length === 10
  },
  validate_special_characters: (name: string) => {
    const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
    const matched_text = format.test(name)
    if (matched_text) {
      throw new Error(`Company name should not contain special characters ${name}`)
    }
  },
  validateUpi: (upiId: string): boolean => {
    const regex = /[a-zA-Z0-9_]{3,}@[a-zA-Z]{2,}/
    return regex.test(upiId)
  },
  callNow: (mobileNo: string) => {
    const regexPattern = /^(\+?91)?(\d{10})$/
    if (mobileNo && mobileNo.startsWith('0')) {
      mobileNo = mobileNo.slice(1)
    }
    if (regexPattern.test(mobileNo)) {
      const formattedMobileNo = '+91' + mobileNo.replace(/^(\+?91)?(\d{10})$/, '$2')
      window.location.href = 'tel:' + formattedMobileNo
    }
  },
  // Function to parse cookie string and retrieve the value of a cookie by its name
  getCookie(cookieString: string, name: string) {
    const cookies = cookieString.split(';').map((cookie) => cookie.trim())
    const cookieObj: { [key: string]: any } = {} // Define the type of cookieObj
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=')
      if (cookieName === name) {
        try {
          cookieObj[name] = JSON.parse(decodeURIComponent(cookieValue))
        } catch (error) {
          cookieObj[name] = decodeURIComponent(cookieValue)
        }
      }
    }
    return cookieObj[name]
  },
  getLatLng: (location: any) => {
    const splitLocation = location.length > 0 ? location?.split(',') : ''
    const long = parseFloat(splitLocation[0]?.replace('(', ''))
    const lati = parseFloat(splitLocation[1]?.replace(')', ''))
    return [long, lati]
  },
  getCustomerIdFromCookie: (request: Request) => {
    const cookieValue = request.headers.get('cookie')
    const customer = util.getCookie(cookieValue || '', 'customer')
    return customer?.user?.customer_id
  },
  getCustomerDataByKey: (request: Request, key: 'mobile' | 'customer_id' | 'id') => {
    const cookieValue = request.headers.get('cookie')
    const customer = util.getCookie(cookieValue || '', 'customer')
    const userValue = customer?.user
    return userValue[key]
  },
  isAccountsUser: (request: Request) => {
    const cookieValue = request.headers.get('cookie')
    const customer = util.getCookie(cookieValue || '', 'customer')
    return customer?.user?.is_accounts_user
  },
  getisShipper: (request: Request) => {
    const cookieValue = request.headers.get('cookie')
    const customer = util.getCookie(cookieValue || '', 'customer')
    return customer?.user?.customer_type_id === 3
  },
  getUserObject: (request: Request) => {
    const cookieValue = request.headers.get('cookie')
    const customer = util.getCookie(cookieValue || '', 'customer')
    return customer?.user
  },
  getIndentFilterParam: ({ users, trucktypes }: IIndentFilter) => {
    const A = users.length > 0 ? `users=${users}` : null
    const B = trucktypes.length > 0 ? `trucktypes=${trucktypes}` : null

    if (A && B) {
      return `${A}&${B}`
    }
    if (A) {
      return A
    }
    if (B) {
      return B
    }
    return null
  },

  getTripFilterParam: ({ users, trucktypes, period, periodType }: ITripFilter) => {
    const A = users.length > 0 ? `users=${users}` : null
    const B = trucktypes.length > 0 ? `trucktypes=${trucktypes}` : null
    const C = period.length > 0 ? `range=${period}&rangeType=${periodType}` : null

    if (A && B && C) {
      return `${A}&${B}&${C}`
    }
    if (A && B) {
      return `${A}&${B}`
    }
    if (B && C) {
      return `${B}&${C}`
    }
    if (A && C) {
      return `${A}&${C}`
    }
    if (A) {
      return A
    }
    if (B) {
      return B
    }
    if (C) {
      return C
    }
    return null
  },
  getCurrentPageName: (pathname: string, defaultPath: string) => {
    const removeFilter = pathname.split('/').filter((p: string) => p !== 'filter')
    const currentPage =
      removeFilter && removeFilter?.length > 0
        ? removeFilter[removeFilter?.length - 1]
        : defaultPath
    return currentPage
  },
  getExtention: (imgUrl: string) => {
    const removeSearch = imgUrl.split('?')[0]
    const ext = removeSearch.split(/[\s.]+/)
    return ext[ext.length - 1]
  },
  fetchApi: ({
    url,
    session_variables,
    input,
  }: {
    url: string
    session_variables: any
    input: any
  }) =>
    fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ session_variables, input }),
    }),
  getTripStatus: (statusName: string) => {
    const statusObj: any = { Received: 'Closed', Paid: 'Invoiced' }
    const status_name = statusObj[statusName] ? statusObj[statusName] : statusName
    return status_name
  },
  updateUrlWithOffset: (basePath: string, offset: number) => {
    const currentUrl = new URL(window.location.href)
    const searchParams = new URLSearchParams(currentUrl.search)

    // Update or add the offset parameter
    searchParams.set('offset', offset.toString())

    // Construct the new path with updated search parameters
    return `${basePath}?${searchParams.toString()}`
  },
  isPageChanging: (state: string) => {
    return ['loading', 'submitting'].includes(state)
  },
  shouldRevalidateForFilter: (input: {
    currentUrl: any
    nextUrl: any
    defaultShouldRevalidate: boolean
  }) => {
    const { currentUrl, nextUrl, defaultShouldRevalidate } = input
    const currentPath = currentUrl?.pathname
    const nextPath = nextUrl?.pathname

    if (nextPath?.includes('filter') || currentPath?.includes('filter')) return false

    return defaultShouldRevalidate
  },
  produceAnalyticsEvent: (eventName: string, other_event: any = {}) => {
    window?.dataLayer?.push({
      event: eventName,
      ...other_event,
    })
  },
  constructPageTitle: (path: string) => {
    const screenNameMapped = util.swapKeysAndValues(constants.urls)
    const title = screenNameMapped[path]
    return title ? `Book - ${util.splitCamelCase(title)}` : 'Book'
  },
  setPageTitle: (path: string, location: string) => {
    const pageTitle = util.constructPageTitle(path)
    document.title = pageTitle
    util.produceAnalyticsEvent(constants?.EVENT_NAME?.PAGE_VIEW, {
      pageTitle: pageTitle,
      pageLocation: location,
    })
    return
  },
  swapKeysAndValues: (obj: any) => {
    const swapped: any = {}
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        swapped[obj[key]] = key
      }
    }
    return swapped
  },
  splitCamelCase: (str: string) => {
    // Split the string by uppercase letters and join with a space
    const result = str.replace(/([A-Z])/g, ' $1').trim()

    // Capitalize the first letter of each word
    return result.replace(/\b\w/g, (char) => char.toUpperCase())
  },
}

export default util
